import { useCallback } from 'react';
import { UseFieldArrayMove } from 'react-hook-form';
import { AccordionItem, AccordionPanel, FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import AccordionButton from 'components/Collapsible/AccordionButton';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface InstaFeedFormSectionProps {
  itemIndex: number;
  prepareFieldName: (name: string) => string;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
  id: string;
}

function InstaFeedFormSection({
  itemIndex,
  prepareFieldName,
  move,
  remove,
  id,
}: InstaFeedFormSectionProps) {
  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(`elementsAttributes[${itemIndex}].${name}`),
    [itemIndex, prepareFieldName],
  );

  return (
    <AccordionItem border={0}>
      <AccordionButton
        id={id}
        index={itemIndex}
        move={move}
        label={`SLAJD ${itemIndex + 1}`}
        remove={remove}
      />

      <AccordionPanel pl={0} pr={0} pt={12} pb={0} overflow="visible">
        <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
          <SectionTitle as="legend" mb={8}>
            Zdjęcie
          </SectionTitle>
          <DropzoneField
            type="image"
            name={fieldname('image')}
            title="Dodaj zdjęcie w formacie .png, .jpg"
          />
        </FormControl>

        <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
          <SectionTitle as="legend" mb={8}>
            Video
          </SectionTitle>
          <DropzoneField
            type="video"
            name={fieldname('video')}
            title="Dodaj plik"
          />
        </FormControl>
        <FormControl as="fieldset" maxW={FORM_BODY_MAX_WIDTH} mb={8}>
          <ToggleCheckbox label="Aktywność" name={fieldname('active')} />
        </FormControl>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default InstaFeedFormSection;
