import omit from 'lodash/omit';
import { Subcategory } from 'types/subcategory';
import { transformLinkParameters } from 'utils/linkCreator';

const PARSED_VALUES = ['logo', 'image'];

const parseDefaultValues = (subCategory: Subcategory) => {
  const result: Record<string, any> = { ...omit(subCategory, PARSED_VALUES) };

  result.withBadge = !!subCategory?.badge;
  result.withImage = !!subCategory?.image?.url;
  result.withLogo = !!subCategory?.logo?.url;
  result.withIcon = !!subCategory?.icon?.url;

  return transformLinkParameters(result);
};

export default parseDefaultValues;
