import * as yup from 'yup';

export const OPERATOR_OPTION = [
  { label: 'OR', value: 'OR' },
  { label: 'AND', value: 'AND' },
];

export const DEFAULT_FORM_VALUE = {
  name: undefined,
  active: false,
  linkParametersAttributes: [{ linkType: undefined }],
  icon: null,
};

export const BODY_SCHEMA = yup.object({
  name: yup.string().required('Pole jest wymagane'),
  linkParametersOperator: yup.string().nullable(),
  image: yup.mixed().nullable(),
  logo: yup.mixed().nullable(),
  active: yup.boolean(),
  badge: yup.string().nullable(),
  badgeColor: yup.string().nullable(),
  badgeTextColor: yup.string().nullable(),
  backgroundColor: yup.string().required('Pole jest wymagane'),
  linkParametersAttributes: yup.array().of(
    yup.object().shape({
      linkType: yup.string().nullable().required('Wybierz rodzaj linku'),
    }),
  ),
  icon: yup.mixed().nullable(),
});
