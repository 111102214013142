import { VStack, Text, Box, HStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FiInstagram } from 'react-icons/fi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';

import { PreviewComponentProps } from 'types/dashboardCreator';
import Headers from 'components/Container/Previews/Headers';
import useGroupsWithFallback from 'utils/useGroupsWithFallback';
import Image from 'components/Container/Previews/PlayWithColorsPreview/Image';
import VideoBannerPreview from 'components/Container/Previews/VideoBannerPreview';

import 'swiper/css';
import 'swiper/css/free-mode';

function InstaFeedPreview({ formValues }: PreviewComponentProps) {
  const elements = useMemo(() => {
    if (!formValues.elementsAttributes) {
      return [...Array(1)];
    }

    return formValues.elementsAttributes;
  }, [formValues]);

  const { groupsProps } = useGroupsWithFallback({ elements, itemsPerGroup: 4 });

  return (
    <Box py={6} bgColor="complementary.lightGrey" overflow="hidden" maxW={388}>
      <Box px={4} pb={4}>
        <Headers
          position="static"
          subtitle="styled by you"
          subtitleFontSize={12}
          title="powered by 4f"
          titleFontSize={24}
          textBackground="transparent"
        />
      </Box>
      <Swiper
        className="mySwiper"
        spaceBetween={12}
        modules={[FreeMode]}
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        freeMode
      >
        {groupsProps.map((group: any, idx: number) => {
          const mainItem = group[0];
          const hasMainItemVideo =
            mainItem.elementAttributes?.video?.url ||
            mainItem.elementAttributes?.video?.path;
          const smallItems = group.slice(1);

          return (
            <SwiperSlide key={`insta_image_${+idx}`}>
              <HStack spacing={2} w="full" alignItems="normal" height="326px">
                <Box width="272px" flexShrink={0}>
                  {hasMainItemVideo ? (
                    <VideoBannerPreview
                      props={{ width: '100%', minH: '100%' }}
                      formValues={mainItem.elementAttributes}
                    />
                  ) : (
                    <Image
                      element={mainItem.elementAttributes}
                      props={{ minH: '100%', minW: '100%', bgSize: 'cover' }}
                    />
                  )}
                </Box>

                <VStack spacing={2} justifyContent="flex-start" w="full">
                  {smallItems.map((item: any) => {
                    if (!item.elementAttributes?.type) return null;
                    const hasVideo =
                      item.elementAttributes?.video?.url ||
                      item.elementAttributes?.video?.path;

                    return (
                      <Box
                        key={`insta_media_small_${item.number}`}
                        width="104px"
                        height="104px"
                        display="flex"
                        alignItems="center"
                      >
                        {hasVideo ? (
                          <VideoBannerPreview
                            props={{ width: '100%', minH: '100%' }}
                            formValues={item.elementAttributes}
                          />
                        ) : (
                          <Image
                            props={{
                              minH: '100%',
                              minW: '100%',
                              bgSize: 'cover',
                            }}
                            element={item.elementAttributes}
                          />
                        )}
                      </Box>
                    );
                  })}
                </VStack>
              </HStack>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <HStack spacing={2} pt={2} px={4}>
        <FiInstagram size={12} />
        <Text fontWeight={600} fontSize={12} transform="uppercase">
          4F OFFICIAL ACCOUNT
        </Text>
      </HStack>
    </Box>
  );
}

export default InstaFeedPreview;
