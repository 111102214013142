import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Accordion, Box, Button } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';

import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';
import InstaFeedFormSection from 'components/Container/Sections/InstaFeedFormSection';
import { DEFAULT_FORM_VALUES } from '../constants';

// kind: instagram_feed
function InstaFeedForm({ prepareFieldName, isEditMode }: FormComponentProps) {
  const { control } = useFormContext();
  const dispatch = useAppDispatch();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: prepareFieldName('elementsAttributes'),
    keyName: 'fieldId',
  });

  const removeElement = useCallback(
    (index: number) => {
      const element = fields[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      remove(index);
    },
    [isEditMode, remove, dispatch, fields],
  );

  const addSlide = useCallback(() => {
    append({
      ...DEFAULT_FORM_VALUES.instagram_feed.elementsAttributes[0],
      placement: fields.length,
    });
  }, [append, fields]);

  return (
    <Box as="fieldset">
      <Accordion defaultIndex={[0, 1, 2, 3]} allowMultiple mb={10}>
        {fields.map((field: any, index) => (
          <InstaFeedFormSection
            id={field.fieldId}
            itemIndex={index}
            prepareFieldName={prepareFieldName}
            key={field.fieldId}
            move={move}
            remove={removeElement}
          />
        ))}
        <Button
          type="button"
          variant="solid"
          mt={4}
          w="100%"
          onClick={addSlide}
          fontSize="12px"
          maxW={FORM_BODY_MAX_WIDTH}
        >
          DODAJ SLIDE
          <Box as="span" ml={2}>
            <BiPlus size={24} />
          </Box>
        </Button>
      </Accordion>
    </Box>
  );
}

export default InstaFeedForm;
