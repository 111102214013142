import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { IoVolumeMuteOutline } from 'react-icons/io5';
import styled from '@emotion/styled';
import { PreviewComponentProps } from 'types/dashboardCreator';
import Headers from 'components/Container/Previews/Headers';
import Button from 'components/Container/Previews/Button';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';

const StyledVideo = styled.video`
  cursor: pointer;
  width: auto;
  /* height: 388px; */
  height: auto;
  z-index: auto;
`;

interface VideoPlayerProps {
  src: any;
}

export function VideoPlayer({ src }: VideoPlayerProps) {
  const ref = useRef<null | HTMLVideoElement>(null);
  const [videoPreview, setVideoPreview] = useState<string | undefined>();
  const [isPlaying, setIsPlaying] = useState<Boolean>(true);

  useEffect(() => {
    let video;
    if (src) {
      // new video selected by dropzone
      if (src.path) {
        video = URL.createObjectURL(src);
      }
      if (src.url) {
        video = src.url;
      }
    }

    setVideoPreview(video);

    return () => {
      setVideoPreview(undefined);
    };
  }, [src]);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }

    if (!isPlaying) {
      ref.current.pause();
      return;
    }

    const playPromise = ref.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(() => {
          // Auto-play was prevented
          // Show paused UI.
        });
    }
  }, [isPlaying]);

  const toggleVideo = () => {
    setIsPlaying((v) => !v);
  };

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <StyledVideo
      onClick={toggleVideo}
      src={videoPreview}
      ref={ref}
      loop
      muted
      autoPlay
    />
  );
}

export interface VideoBannerPreviewProps extends PreviewComponentProps {
  children?: JSX.Element[] | JSX.Element | undefined;
  // eslint-disable-next-line react/require-default-props
  props?: any;
}

function VideoBannerPreview({
  formValues,
  showStats,
  children,
  props,
}: VideoBannerPreviewProps) {
  const isVideoUrl = !!formValues.video?.url || !!formValues.video?.path;

  const shouldRenderHeaders = useMemo(
    () => Boolean(formValues.subtitle || formValues.title),
    [formValues.subtitle, formValues.title],
  );

  return (
    <Flex
      width={388}
      minH={150}
      position="relative"
      alignItems="center"
      justifyContent="center"
      backgroundColor="complementary.whiteGrey"
      {...props}
    >
      {shouldRenderHeaders && <Headers {...formValues} />}
      {isVideoUrl && <VideoPlayer src={formValues.video} />}
      {showStats && <ShowStatsIcon data={formValues} />}
      {formValues.hasSound && (
        <Box position="absolute">
          <IoVolumeMuteOutline size={31} color="#FFFFFF" />
        </Box>
      )}
      {formValues.buttonText && <Button {...formValues} />}
      {children}
    </Flex>
  );
}
VideoBannerPreview.defaultProps = {
  children: undefined,
};

export default VideoBannerPreview;
