import {
  FormControl,
  CheckboxProps as ChakraCheckboxProps,
  FormLabel,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import get from 'lodash/get';

interface RadioBoxControlProps extends ChakraCheckboxProps {
  name: string;
  label?: string | React.ReactNode;
  group?: boolean;
  isClearable?: boolean;
  isReadOnly?: boolean;
  onChangeCallback?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const Label = styled(FormLabel)`
  position: relative;
  cursor: pointer;

  > input {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -9999px;

    &:checked {
      & + span {
        background-color: #0b24fb;
        color: #fff;
      }
    }
  }

  > span {
    display: block;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
`;

function RadioBoxControl({
  id,
  label,
  name,
  isRequired,
  defaultChecked,
  value,
  group,
  isClearable,
  isReadOnly,
  onChangeCallback,
}: RadioBoxControlProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      w="auto"
    >
      <Label
        htmlFor={id || name}
        paddingRight="16px"
        opacity={isReadOnly ? 0.5 : 1}
      >
        <Controller
          control={control}
          name={name}
          defaultValue={defaultChecked}
          render={({ field: { onChange, value: formValue } }) => (
            <>
              <input
                id={id || name}
                type="radio"
                name={name}
                value={value || ''}
                checked={value === formValue}
                onClick={() => {
                  if (isClearable && value === formValue) onChange(undefined);
                }}
                onChange={(e) => {
                  if (!isReadOnly) {
                    onChange(e);
                    onChangeCallback?.(e);
                  }
                }}
              />
              <Text
                as="span"
                padding="4px 16px"
                fontSize={16}
                display="block"
                border="1px #0b24fb solid"
                borderColor={
                  errorMessage ? 'complementary.red' : 'complementary.blue'
                }
              >
                {label}
              </Text>
            </>
          )}
        />
      </Label>
      {!group && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

RadioBoxControl.defaultProps = {
  label: '',
  group: false,
  isClearable: false,
  isReadOnly: false,
  onChangeCallback: null,
};

export default RadioBoxControl;
