import 'swiper/css';
import 'swiper/css/free-mode';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Text, Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { PreviewComponentProps } from 'types/dashboardCreator';
import Slide from 'components/Container/Previews/TitledCarouselPreview/Slide';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import { Dashboard } from 'types/dashboard';

enum BUTTON {
  arrow = 'arrow',
  label = 'label',
}

const StyledSlide = styled(SwiperSlide)`
  padding: 0;
  width: auto;
`;

function TitledCarouselPreview({
  formValues,
  showStats,
}: PreviewComponentProps) {
  return (
    <Box maxW={388} px={4}>
      {(formValues.title || formValues.buttonKind) && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap="8px"
          py={6}
          pr={4}
        >
          <Text
            display="inline-block"
            minH={6}
            fontSize="16px"
            fontWeight={700}
            textTransform="uppercase"
            dangerouslySetInnerHTML={{
              __html: formValues.title,
            }}
          />
          {formValues.buttonKind === BUTTON.arrow && (
            <AiOutlineArrowRight size={16} />
          )}
          {formValues.buttonKind === BUTTON.label && (
            <Text
              fontSize="12px"
              fontWeight={700}
              textTransform="uppercase"
              textAlign="right"
            >
              {formValues.buttonText}
            </Text>
          )}
        </Flex>
      )}
      <Swiper
        spaceBetween={8}
        modules={[FreeMode]}
        freeMode={{
          enabled: false,
          sticky: true,
        }}
        watchSlidesProgress
        slidesPerView="auto"
      >
        {formValues?.elementsAttributes?.map((element: any, idx: number) => (
          <StyledSlide key={`slide-preview-${+idx}`}>
            <Slide element={element} />
            {showStats && <ShowStatsIcon data={element as Dashboard} />}
          </StyledSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default TitledCarouselPreview;
