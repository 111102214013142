import { useCallback } from 'react';
import { AccordionItem, AccordionPanel, FormControl } from '@chakra-ui/react';
import { UseFieldArrayMove } from 'react-hook-form';

import AccordionButton from 'components/Collapsible/AccordionButton';
import GraphicTileSmall from 'components/Container/Forms/GraphicTileSmallForm';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import Select from 'components/Form/Select';

interface GraphicTileSmallSliderSectionProps {
  id: string;
  elementIndex: number;
  prepareFieldName: (name: string) => string;
  isEditMode: boolean;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
}

const DURATION_OPTIONS = Array.from({ length: 5 }, (_, i) => ({
  label: `${i + 1}`,
  value: i + 1,
}));

function GraphicTileSmallSliderSection({
  id,
  elementIndex,
  prepareFieldName,
  isEditMode,
  move,
  remove,
}: GraphicTileSmallSliderSectionProps) {
  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(`elementsAttributes[${elementIndex}].${name}`),
    [elementIndex, prepareFieldName],
  );

  return (
    <AccordionItem border={0}>
      <AccordionButton
        id={id}
        index={elementIndex}
        move={move}
        label={`SLAJD ${elementIndex + 1}`}
        remove={remove}
      />
      <AccordionPanel pl={0} pr={0} pt={12} pb={0} overflow="visible">
        <FormControl as="fieldset" mb={8} maxW={FORM_BODY_MAX_WIDTH}>
          <Select
            name={fieldname('duration')}
            label="Czas trwania (w sekundach)"
            options={DURATION_OPTIONS}
          />
        </FormControl>
        <GraphicTileSmall
          prepareFieldName={fieldname}
          isEditMode={isEditMode}
        />
      </AccordionPanel>
    </AccordionItem>
  );
}

export default GraphicTileSmallSliderSection;
