/* eslint-disable import/no-cycle */
import { FunctionComponent } from 'react';
import {
  FormComponentProps,
  PreviewComponentProps,
} from 'types/dashboardCreator';

import GraphicTileCarouselForm from 'components/Container/Forms/GraphicTileCarouselForm';
import ButtonForm from 'components/Container/Forms/ButtonForm';
import PlayWithColorsForm from 'components/Container/Forms/PlayWithColorsForm';
import GraphicTileSmallForm from 'components/Container/Forms/GraphicTileSmallForm';
import PhotoBannerMediumForm from 'components/Container/Forms/PhotoBannerMediumForm';
import PhotoBannerLargeForm from 'components/Container/Forms/PhotoBannerLargeForm';
import FrameBanner from 'components/Container/Forms/FrameBannerForm';
import TextBannerForm from 'components/Container/Forms/TextBannerForm';
import TextBannerImageForm from 'components/Container/Forms/TextBannerImageForm';
import DiscountBannerForm from 'components/Container/Forms/DiscountBannerForm';
import ParallaxBannerForm from 'components/Container/Forms/ParallaxBannerForm';
import NewsBannerForm from 'components/Container/Forms/NewsBannerForm';
import VideoBannerForm from 'components/Container/Forms/VideoBannerForm';
import SquareBannerForm from 'components/Container/Forms/SquareBannerForm';
import ImageGalleryForm from 'components/Container/Forms/ImageGalleryForm';
import ChosenForUForm from 'components/Container/Forms/ChosenForUForm';
import LastChanceForm from 'components/Container/Forms/LastChanceForm';
import ProductsCarouselForm from 'components/Container/Forms/ProductsCarouselForm';
import ProductsListForm from 'components/Container/Forms/ProductsListForm';
import InstaFeedForm from 'components/Container/Forms/InstaFeedForm';
import AppstoriesForm from 'components/Container/Forms/AppstoriesForm';
import ExternalLinkForm from 'components/Container/Forms/ExternalLinkForm';
import TitledCarouselForm from 'components/Container/Forms/TitledCarouselForm';
import TimerBannerForm from 'components/Container/Forms/TimerBannerForm';
import StylizationForm from 'components/Container/Forms/StylizationForm';
import BasketballBannerForm from 'components/Container/Forms/BasketballBannerForm';

import PhotoBannerLargePreview from 'components/Container/Previews/PhotoBannerLargePreview';
import TextBannerImagePreview from 'components/Container/Previews/TextBannerImagePreview';
import GraphicTileSmallPreview from 'components/Container/Previews/GraphicTileSmallPreview';
import PhotoBannerMediumPreview from 'components/Container/Previews/PhotoBannerMediumPreview';
import LastChanceBannerPreview from 'components/Container/Previews/LastChanceBannerPreview';
import TextBannerPreview from 'components/Container/Previews/TextBannerPreview';
import PhotoBannerCarouselPreview from 'components/Container/Previews/PhotoBannerCarouselPreview';
import ImageGalleryPreview, {
  ImageGalleryPreviewProps,
} from 'components/Container/Previews/ImageGalleryPreview';
import ButtonsPreview from 'components/Container/Previews/ButtonsPreview';
import ParallaxBannerPreview, {
  ParallaxBannerPreviewProps,
} from 'components/Container/Previews/ParallaxBannerPreview';
import TextBannerSlimPreview from 'components/Container/Previews/TextBannerSlimPreview';
import DiscountBannerPreview from 'components/Container/Previews/DiscountBannerPreview';
import VideoBannerPreview, {
  VideoBannerPreviewProps,
} from 'components/Container/Previews/VideoBannerPreview';
import FrameBannerPreview from 'components/Container/Previews/FrameBannerPreview';
import ProductCarouselPreview from 'components/Container/Previews/ProductCarouselPreview';
import ProductsListPreview from 'components/Container/Previews/ProductsListPreview';
import SquareBannerPreview from 'components/Container/Previews/SquareBannerPreview';
import PlayWithColorsPreview from 'components/Container/Previews/PlayWithColorsPreview';
import InstaFeedPreview from 'components/Container/Previews/InstaFeedPreview';
import ChosenForYouPreview from 'components/Container/Previews/ChosenForYouPreview';
import AppstoriesPreview from 'components/Container/Previews/AppstoriesPreview';
import ExternalLinkPreview from 'components/Container/Previews/ExternalLinkPreview';
import TitledCarouselPreview from 'components/Container/Previews/TitledCarouselPreview';
import TimerBannerPreview from 'components/Container/Previews/TimerBannerPreview';
import StylizationPreview from 'components/Container/Previews/StylizationPreview';
import BasketballBannerPreview from 'components/Container/Previews/BasketballBannerPreview';

export enum GENDER {
  female = 'Kobieta',
  male = 'Mężczyzna',
  girls = 'Dziewczyna',
  boys = 'Chłopak',
  all = 'Wszystko',
}

export const STORE_DICT: {
  [K: string]: string;
} = {
  four_fstore_pl: 'Polska',
  four_fstore_sk: 'Słowacja',
  four_fstore_lt: 'Litwa',
  four_fstore_lv: 'Łotwa',
  four_fstore_com: 'Unia Europejska',
  four_fstore_de: 'Niemcy',
  four_fstore_cz: 'Czechy',
  four_fstore_ro: 'Rumunia',
  four_fstore_ua: 'Ukraina',
  four_fstore_hr: 'Chorwacja',
};

export const GENDER_BY_STORE: {
  [key: string]: { female: string; male: string; girls: string; boys: string };
} = {
  // pl
  default: {
    female: 'Kobieta',
    male: 'Mężczyzna',
    girls: 'Dziewczyna',
    boys: 'Chłopak',
  },
  four_fstore_sk: {
    female: 'Žena',
    male: 'Muž',
    girls: 'Dievča',
    boys: 'Chlapec',
  },
  four_fstore_lt: {
    female: 'Moteris',
    male: 'vyras',
    girls: 'Mergina',
    boys: 'Berniukas',
  },
  four_fstore_lv: {
    female: 'Sieviete',
    male: 'Vīrietis',
    girls: 'Meitene',
    boys: 'Zēns',
  },
  four_fstore_com: {
    female: 'Female',
    male: 'Male',
    girls: 'Girl',
    boys: 'Boy',
  },
  four_fstore_de: {
    female: 'Frau',
    male: 'Mann',
    girls: 'Mädchen',
    boys: 'Junge',
  },
  four_fstore_cz: {
    female: 'Žena',
    male: 'Muž',
    girls: 'Dívka',
    boys: 'Chlapec',
  },
  four_fstore_ro: {
    female: 'Femeie',
    male: 'Bărbat',
    girls: 'Fata',
    boys: 'băiat',
  },
  four_fstore_ua: {
    female: 'Жінка',
    male: 'Чоловік',
    girls: 'Дівчина',
    boys: 'Хлопчик',
  },
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum COMPONENT_HINTS {
  graphic_tile_carousel = 'Stwórz od 2-4 banerów i wyświetl je w postaci karuzeli',
}

export const ALIGNMENTS: { [key: string]: string } = {
  left: 'LEWA',
  center: 'ŚRODEK',
  right: 'PRAWA',
};

export const DEFAULT_FORM_VALUES: { [key: string]: any } = {
  external_link: {
    active: true,
    textColor: '#000000',
    backgroundColor: '#f9f9f9',
  },
  graphic_tile: {
    active: true,
    kind: 'graphic_tile',
    image: '',
    textColor: '#000000',
    textBackground: '#ffffff',
    title: '',
    subtitle: '',
    titleFontSize: 12,
    subtitleFontSize: 12,
    alignment: 'center',
    buttonText: '',
    buttonTextColor: '#000000',
    buttonColor: '#ffffff',
    gender: 'all',
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    withTags: false,
    photoTagsAttributes: [
      { linkType: 'skus[]=param_value', linkParamValue: '' },
    ],
  },
  text_banner: {
    active: true,
    alignment: 'center',
    kind: '',
    image: '',
    textColor: '#000000',
    contentTextColor: '#000000',
    content: '',
    contentAlignment: 'center',
    buttonText: '',
    buttonColor: '#ffffff',
    buttonTextColor: '#000000',
    titleAlignment: 'left',
    gender: 'all',
    marginColor: '#ffffff',
    backgroundColor: '#ffffff',
    textHighlight: null,
    linkParametersAttributes: [{ linkType: null }],
  },
  text_banner_slim: {
    active: true,
    type: 'text',
    textColor: '#FFFFFF',
    backgroundColor: '#000000',
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
  },
  text_banner_image: {
    active: true,
    alignment: 'center',
    buttonText: 'Sprawdź',
    buttonTextColor: '#000000',
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    textColor: '#000000',
  },
  graphic_tile_carousel: {
    active: true,
    elementsAttributes: [
      {
        active: true,
        linkParametersAttributes: [{ linkType: null }],
        type: 'image',
        navigationColor: '#ffffff',
        placement: 0,
        textColor: '#000000',
        textBackground: '#ffffff',
        buttonTextColor: '#000000',
        buttonColor: '#ffffff',
        withTags: false,
        photoTagsAttributes: [
          { linkType: 'skus[]=param_value', linkParamValue: '' },
        ],
      },
    ],
    marginColor: '#ffffff',
  },
  video_banner: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    titleFontSize: 12,
    subtitleFontSize: 12,
    textColor: '#000000',
    textBackground: '#ffffff',
    alignment: 'left',
    buttonTextColor: '#000000',
    hasSound: false,
  },
  graphic_tile_small: {
    active: true,
    title: '',
    subtitle: '',
    titleFontSize: 12,
    subtitleFontSize: 12,
    textColor: '#000000',
    textBackground: '#ffffff',
    alignment: 'center',
    buttonText: '',
    buttonTextColor: '#000000',
    buttonColor: '#ffffff',
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
  },
  photo_banner_medium: {
    active: true,
    alignment: 'center',
    title: '',
    subtitle: '',
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    textColor: '#000000',
    textBackground: '#ffffff',
    buttonTextColor: '#000000',
    buttonColor: '#ffffff',
    withTags: false,
    photoTagsAttributes: [
      { linkType: 'skus[]=param_value', linkParamValue: '' },
    ],
  },
  square_banner: {
    active: true,
    title: '',
    subtitle: '',
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    textColor: '#000000',
    textBackground: '#ffffff',
  },
  frame_banner: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    textBackground: '#f9faf9',
  },
  play_with_colors: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    textColor: '#000000',
    textBackground: '#ffffff',
  },
  paralax_banner: {
    active: true,
    title: '',
    subtitle: '',
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    textColor: '#000000',
    textBackground: '#ffffff',
    buttonColor: '#000000',
    buttonTextColor: '#000000',
  },
  discount_banner: {
    active: true,
    backgroundColor: '#FFFFFF',
    insideBoxBackgroundColor: '#FFFFFF',
    title: '',
    subtitle: '',
    disclaimerFontSize: 12,
    titlePrefixFontSize: 12,
    marginColor: '#ffffff',
    textColor: '#000000',
    textBackground: '#ffffff',
    linkParametersAttributes: [{ linkType: null }],
    contentTextColor: '#000000',
  },
  products_carousel: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    buttonColor: '#ffffff',
    buttonTextColor: '#000000',
  },
  products_carousel_horizontal: {
    active: true,
    marginColor: '#ffffff',
    buttonColor: '#ffffff',
    buttonTextColor: '#000000',
  },
  chosen_for_you: { active: true, marginColor: '#ffffff' },
  appstories: {
    active: true,
    marginColor: '#ffffff',
    backgroundColor: '#ffffff',
    borderColor: '#000000',
    elementsAttributes: [
      {
        image: null,
        title: '',
        linkParametersAttributes: [{ linkType: null }],
        active: true,
        placement: 0,
        textColor: '#000000',
        titleFontSize: 12,
      },
      {
        image: null,
        title: '',
        linkParametersAttributes: [{ linkType: null }],
        active: true,
        placement: 1,
        textColor: '#000000',
        titleFontSize: 12,
      },
      {
        image: null,
        title: '',
        linkParametersAttributes: [{ linkType: null }],
        active: true,
        placement: 2,
        textColor: '#000000',
        titleFontSize: 12,
      },
    ],
  },
  instagram_feed: {
    active: true,
    marginColor: '#ffffff',
    elementsAttributes: Array.from({ length: 4 }).fill({
      active: true,
      type: 'image',
      image: null,
      video: null,
      placement: 0,
    }),
  },
  solid_button: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    withTitle: true,
    backgroundColor: '#000000',
    buttonTextColor: '#ffffff',
    insideBoxBackgroundColor: '#ffffff',
  },
  outlined_button: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    withTitle: true,
    buttonTextColor: '#000000',
    backgroundColor: '#000000',
    insideBoxBackgroundColor: '#ffffff',
  },
  label_button: {
    active: true,
    alignment: 'center',
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    withTitle: true,
    buttonTextColor: '#000000',
    insideBoxBackgroundColor: '#ffffff',
  },
  link_button: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    withTitle: true,
    buttonTextColor: '#000000',
    insideBoxBackgroundColor: '#ffffff',
  },
  image_gallery: {
    active: true,
    type: 'withHeaders',
    elementsAttributes: [
      {
        active: true,
        linkParametersAttributes: [{ linkType: null }],
        text_color: '#000000',
        deleteVideo: false,
      },
    ],
    marginColor: '#ffffff',
  },
  last_chance: {
    active: true,
    textColor: '#000000',
    textBackground: '#ffffff',
    title: '',
    subtitle: '',
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    buttonColor: '#ffffff',
    buttonTextColor: '#000000',
    withTags: false,
    hasSound: false,
    photoTagsAttributes: [
      { linkType: 'skus[]=param_value', linkParamValue: '' },
    ],
  },
  products_list: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    marginColor: '#ffffff',
    buttonTextColor: '#000000',
    buttonColor: '#ffffff',
  },
  titled_carousel: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    elementsAttributes: [
      {
        active: true,
        placement: 0,
        titleFontSize: 12,
        subtitleFontSize: 12,
        textColor: '#000000',
        textBackground: '#ffffff',
        image: null,
        linkParametersAttributes: [{ linkType: null }],
      },
    ],
  },
  timer_banner: {
    active: true,
    linkParametersAttributes: [{ linkType: null }],
    titleAlignment: 'center',
    titleFontSize: 12,
    textColor: '#000000',
    backgroundColor: '#ffffff',
    textBackground: '#ffffff',
  },
  stylization: {
    active: true,
    title: '4F stylizacje',
    titleFontSize: 12,
    elementsAttributes: [
      {
        active: true,
        placement: 0,
        image: null,
        photoTagsAttributes: [
          { linkType: 'skus[]=param_value', linkParamValue: '' },
        ],
      },
    ],
  },
  basketball_banner: {
    image: null,
    title: 'Strefa fana',
    backgroundColor: '#000000',
    textColor: '#FFFFFF',
    buttonText: 'Basket liga',
    buttonColor: '#E5253A',
    buttonTextColor: '#FFFFFF',
    linkParametersAttributes: [{ linkType: null }],
  },
};

export const FormComponents: {
  [key: string]: FunctionComponent<FormComponentProps>;
} = {
  graphic_tile_carousel: GraphicTileCarouselForm,
  play_with_colors: PlayWithColorsForm,
  graphic_tile_small: GraphicTileSmallForm,
  graphic_tile: PhotoBannerLargeForm,
  solid_button: ButtonForm({ advanced: false }),
  outlined_button: ButtonForm({ advanced: false }),
  label_button: ButtonForm({ advanced: true }),
  link_button: ButtonForm({ advanced: true }),
  text_banner_image: TextBannerImageForm,
  frame_banner: FrameBanner,
  photo_banner_medium: PhotoBannerMediumForm,
  text_banner: TextBannerForm,
  discount_banner: DiscountBannerForm,
  paralax_banner: ParallaxBannerForm,
  video_banner: VideoBannerForm,
  text_banner_slim: NewsBannerForm,
  square_banner: SquareBannerForm,
  image_gallery: ImageGalleryForm,
  chosen_for_you: ChosenForUForm,
  last_chance: LastChanceForm,
  products_carousel: ProductsCarouselForm,
  products_list: ProductsListForm,
  appstories: AppstoriesForm,
  external_link: ExternalLinkForm,
  instagram_feed: InstaFeedForm,
  titled_carousel: TitledCarouselForm,
  timer_banner: TimerBannerForm,
  stylization: StylizationForm,
  basketball_banner: BasketballBannerForm,
};

export type PreviewProps =
  | PreviewComponentProps
  | VideoBannerPreviewProps
  | ParallaxBannerPreviewProps
  | ImageGalleryPreviewProps;

export const Previews: {
  [key: string]: FunctionComponent<PreviewProps>;
} = {
  graphic_tile: PhotoBannerLargePreview,
  text_banner: TextBannerPreview,
  text_banner_image: TextBannerImagePreview,
  graphic_tile_carousel: PhotoBannerCarouselPreview,
  graphic_tile_small: GraphicTileSmallPreview,
  photo_banner_medium: PhotoBannerMediumPreview,
  last_chance: LastChanceBannerPreview,
  image_gallery: ImageGalleryPreview,
  solid_button: ButtonsPreview({ variant: 'solid' }),
  outlined_button: ButtonsPreview({ variant: 'outlined' }),
  label_button: ButtonsPreview({ variant: 'label' }),
  link_button: ButtonsPreview({ variant: 'link' }),
  paralax_banner: ParallaxBannerPreview,
  text_banner_slim: TextBannerSlimPreview,
  discount_banner: DiscountBannerPreview,
  video_banner: VideoBannerPreview,
  frame_banner: FrameBannerPreview,
  products_carousel: ProductCarouselPreview,
  products_list: ProductsListPreview,
  square_banner: SquareBannerPreview,
  play_with_colors: PlayWithColorsPreview,
  instagram_feed: InstaFeedPreview,
  chosen_for_you: ChosenForYouPreview,
  appstories: AppstoriesPreview,
  external_link: ExternalLinkPreview,
  titled_carousel: TitledCarouselPreview,
  timer_banner: TimerBannerPreview,
  stylization: StylizationPreview,
  basketball_banner: BasketballBannerPreview,
};

export const EMPTY_COMPONENT = {
  name: null,
  kind: '',
  userKind: 'both',
  active: true,
  linkType: '',
  gender: 'all',
  productSKU: null,
  image: null,
  video: null,
  title: null,
  elementsAttributes: [],
  specialElementsAttributes: [],
  linkParametersAttributes: [],
  linkParametersOperator: '',
  backgroundImage: null,
  buttonText: '',
  content: '',
  insideBoxText: '',
  timeTo: null,
  dateTo: null,
  subtitle: null,
  titlePrefix: null,
  buttonKind: null,
  backgroundColor: null,
  externalUrl: null,
  borderColor: null,
  hasSound: null,
  textHighlight: null,
  photoTagsAttributes: [],
  contentTextColor: null,
};

export const FIT_CONTENT: {
  [key: string]: string;
} = {
  graphic_tile_carousel: 'fit-content',
  image_gallery: 'fit-content',
  play_with_colors: 'fit-content',
};

export const CART_PADDING: {
  [key: string]: string;
} = {
  products_carousel: '32px 0 32px 32px',
};

export const CONTAINERS_WITHOUT_STATS = [
  'solid_button',
  'outlined_button',
  'label_button',
  'link_button',
  'external_link',
];
