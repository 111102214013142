import Slide from 'components/Container/Previews/PhotoBannerCarouselPreview/Slide';
import { PreviewComponentProps } from 'types/dashboardCreator';
import ImagesSlider from 'components/ImagesSlider';
import GraphicTileSmallPreview from 'components/Container/Previews/GraphicTileSmallPreview';

function SliderGraphicTileSmallPreview({
  formValues: { elementsAttributes: elements },
  showStats,
}: PreviewComponentProps) {
  return elements?.length ? (
    <ImagesSlider
      elements={elements}
      imageRatio={3}
      SlideComponent={({ element, showStats: showElementStats }) =>
        GraphicTileSmallPreview({
          formValues: element,
          showStats: showElementStats,
        })
      }
      showStats={showStats}
      hidePagination
    />
  ) : (
    <Slide element={{}} />
  );
}

export default SliderGraphicTileSmallPreview;
