import { Text, HStack } from '@chakra-ui/react';
import { isNull } from 'lodash';
import useQueryParams from 'utils/useQueryParams';

interface SearchIndicatorProps {
  searchKey: string;
}

function SearchIndicator({ searchKey }: SearchIndicatorProps) {
  const { search } = useQueryParams();

  const searchPhrase = search.get(searchKey);

  if (isNull(searchPhrase)) return null;

  return (
    <HStack paddingY="16px">
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight={400}
        color="complementary.lightBlack"
      >
        Wyniki wyszukiwania:
      </Text>
      <Text fontFamily="Inter" fontSize="16px" fontWeight={700}>
        {searchPhrase}
      </Text>
    </HStack>
  );
}

export default SearchIndicator;
